<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <h2 class="text-h5 mb-2">{{ escola.descricao }}</h2>
        <e-label>Pesquise uma unidade escolar aqui</e-label>
        <v-autocomplete
          v-model="escola"
          :items="this.franquiasList"
          item-text="descricao"
          item-value="id"
          dense
          return-object
          filled
          label="Pesquise uma escola"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <!--  -->
    <v-row>
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="listarTurmasEscola(escola.id)">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Turmas</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold"> Escola atual (sem alunos)</v-card-text>
        </v-card>
      </v-col>
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="listarTodasTurmas()">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Todas as turmas</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">
            Todas as escolas (sem alunos)
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Relatório aulas -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="insercaoAulas()">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Aulas</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold"> Relatório inserção de aulas </v-card-text>
        </v-card>
      </v-col>
      <!-- Relatório aulas -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="insercaoAulasXls()">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Aulas</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">
            Relatório inserção de aulas Planilha
          </v-card-text>
        </v-card>
      </v-col>
      <!-- relatorio de lotação de professores-->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="lotacao()">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Lotação</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold"> Todas as escolas </v-card-text>
        </v-card>
      </v-col>
      <!-- boletins de uma escola -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="boletinsFranquia(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Boletins</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold"> Escola atual </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <!-- relatorios bncc de uma escola -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="relatoriosBnccFranquia(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">BNCC</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold"> Escola atual </v-card-text>
        </v-card>
      </v-col>
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="alunosPorUnidades(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Turmas</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold"> Escola atual </v-card-text>
        </v-card>
      </v-col>
      <!--  -->
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="aniversarioPorUnidades(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-cake </v-icon>
            <span class="title font-weight-light"> Aniversariantes </span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold"> Escola atual </v-card-text>
        </v-card>
      </v-col>
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="escolasTurmasAlunos()">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Todas as turmas</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold"> Todas as escolas </v-card-text>
        </v-card>
      </v-col>
      <!--  -->
      <!-- Movimento escolar -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="resumoMovimentoEscolar(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Resumo movimento escolar</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold"> Escola atual</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="matriculasPorEscola()">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Matriculas por escolas</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold">Todas as escolas</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="matriculasPorSerie()">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Matriculas por serie</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold">Todas as escolas</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="relatorioDeListagemDosPlanosLancados(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Planos lançados</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">Escola Atual</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card
          color="primary lighten-1"
          @click="relatorioDeListagemDosPlanosLancadosGeral(escola.id)"
        >
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Planos lançados</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">Todas as escolas</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="alunosNaoMatriculados(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Alunos não matriculados</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">Escola Atual</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="alunosNaoMatriculadosGeral()">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Alunos não matriculados</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">Todas as escolas</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="qtdProfessoresPorDisciplina()">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Professores por disciplina</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">Todas as escolas</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="relatoriosEvasao(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Faltas</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">Escola Atual</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="relatorioAulas(escola.id)">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Situação de Aulas</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">Escola Atual</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="relatorioAulasGeral()">
          <template slot="progress">
            <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Situação de Aulas</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold">Todas as escolas</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Relatórios",
  data() {
    return {
      escola: {
        descricao: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    ...mapGetters("Franquias", ["franquiasList", "carregandoFranquias"]),
  },
  methods: {
    async alunosPorUnidades(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.alunosPorUnidadeEScolar(parseInt(franquia_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async aniversarioPorUnidades(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.aniversarioPorUnidadeEScolar(
          parseInt(franquia_id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async lotacao() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.lotacao();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async boletinsFranquia(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.boletinsFranquia(parseInt(franquia_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async relatoriosBnccFranquia(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.relatoriosBnccFranquia(parseInt(franquia_id, 10));
      } catch (error) {
        this.$toast.danger("Não encontramos turmas de infantil vinculado a essa escola!");
        // this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async inscritosCongresso() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.inscritosCongresso();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async escolasTurmasAlunos() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.escolasTurmasAlunos();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async listarTodasTurmas() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.listarTodasTurmas();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async listarTurmasEscola(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.listarTurmasFranquia(parseInt(franquia_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async insercaoAulas() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.insercaoAulas();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async insercaoAulasXls() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.insercaoAulasXls();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async resumoMovimentoEscolar(fraquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.resumoMovimentoEscolar(fraquia_id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async matriculasPorEscola() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.matriculasPorEscola();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async matriculasPorSerie() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.matriculasPorSerie();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async relatoriosEvasao(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.relatoriosEvasao(parseInt(franquia_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async relatorioAulas(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.relatorioAulas(parseInt(franquia_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async relatorioAulasGeral() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.relatorioAulasGeral();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async relatorioDeListagemDosPlanosLancados(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.relatorioDeListagemDosPlanosLancados(
          parseInt(franquia_id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async relatorioDeListagemDosPlanosLancadosGeral() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.relatorioDeListagemDosPlanosLancadosGeral();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async alunosNaoMatriculados(franquia_id) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.alunosNaoMatriculados(parseInt(franquia_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async alunosNaoMatriculadosGeral() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.alunosNaoMatriculadosGeral();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async qtdProfessoresPorDisciplina() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.qtdProfessoresPorDisciplina();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
  mounted() {
    this.$loaderService.open("Carregando dados...");
    this.escola = this.user.franquia;
    this.$loaderService.close();
  },
};
</script>
